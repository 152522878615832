import { HttpService } from '../../../services/http.service';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/internal/Observable';
import { OriginatorInterface } from '../../../reserve-profile/common/interfaces/originator.interface';
import { AssessedDataInterface } from '../interfaces/assessed-data.interface';
import { AcquisitionProcessRequestInterface } from '../../../shared/interfaces/acquisition/acquisition-process-request.interface';
import {
  AcquisitionUpdateDataInterface
} from "../../../shared/interfaces/acquisition/acquisition-update-data.interface";

@Injectable({ providedIn: 'root' })
export class AssessHttpService extends HttpService {
  getAssessedData(): Observable<AssessedDataInterface> {
    return this.http.get<AssessedDataInterface>(`${this.baseUrl}acquisition/created`)
  }

  processAssessData(snapshot:{status: string, body: AcquisitionProcessRequestInterface[]}): Observable<any> {
    return this.http.patch(`${this.baseUrl}acquisition/process/${snapshot.status}`, snapshot.body);
  }

  updateAssessData(snapshot:{id: string, body: AcquisitionUpdateDataInterface}): Observable<any> {
    return this.http.patch(`${this.baseUrl}acquisition/${snapshot.id}`, snapshot.body);
  }

  rejectAssessData( body: AcquisitionProcessRequestInterface): Observable<any> {
    return this.http.post(`${this.baseUrl}acquisition/reject`, body);
  }

  uploadFile(file: any): Observable<any> {
    const formData = new FormData();
    formData.append('file', file, file.name);
    return this.http.post(`${this.baseUrl}document/upload-file`, formData);
  }

  uploadAssesFile(formData: any): Observable<any> {
    return this.http.post(`${this.baseUrl}document/upload-file`, formData);
  }

  deleteUploadedFile(id: string): Observable<any> {
    const baseUrl = this.baseUrl + `document/${id}`;
    return this.http.delete(baseUrl);
  }

  downloadFile(fileId: any): Observable<any> {
    return this.http.get(`${this.baseUrl}document/${fileId}/download`);
  }

  getOriginatorList(): Observable<OriginatorInterface[]> {
    return this.http.get<OriginatorInterface[]>(`${this.baseUrl}entity/originators`);
  }

  getDebtorsList(): Observable<any[]> {
    return this.http.get<any[]>(`${this.baseUrl}entity/debtors`);
  }

  getCreditorsList(): Observable<any[]> {
    return this.http.get<any[]>(`${this.baseUrl}entity/creditors`);
  }
}

import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { MsalGuard } from '@azure/msal-angular';

import { FullComponent } from './layouts/full/full.component';
import { InvestmentsComponent } from './investments/investments/investments.component';
import { UploadsComponent } from './uploads/uploads/uploads.component';
import { InvestmentComponent } from './crystalreports/investment/investment.component';
import { LoginComponent } from './login/login.component';
import { MasterbankComponent } from './crystalreports/masterbank/masterbank.component';

import { FxrateComponent } from './crystalreports/fxrate/fxrate.component';
import { ChartsComponent } from './charts/charts.component';

export const profilePaths = ['originator'];

export const Approutes: Routes = [
  {
    path: '',
    component: FullComponent,
    children: [
      { path: '', redirectTo: '/dashboard', pathMatch: 'full' },
      {
        path: 'cte/investments',
        component: InvestmentsComponent,
        canActivate: [MsalGuard],
      },
      {
        path: 'charts',
        component: ChartsComponent,
        canActivate: [MsalGuard],
      },
      {
        path: 'reports/investment',
        component: InvestmentComponent,
        canActivate: [MsalGuard],
      },
      {
        path: 'reports/masterbank',
        component: MasterbankComponent,
        canActivate: [MsalGuard],
      },
      {
        path: 'cte/transactions',
        loadChildren: () => import('./cash-transaction/cash-transactions.module').then((m) => m.CashTransactionsModule),
        canActivate: [MsalGuard],
      },
      {
        path: 'finance/postings',
        loadChildren: () => import('./finance-postings/finance-postings.module').then((m) => m.FinancePostingsModule),
        canActivate: [MsalGuard],
      },
      {
        path: 'finance/transactions',
        loadChildren: () => import('./finance-transactions/finance-transactions.module').then((m) => m.FinanceTransactionsModule),
        canActivate: [MsalGuard],
      },
      {
        path: 'finance/accounts',
        loadChildren: () => import('./finance-accounts/finance-accounts.module').then((m) => m.FinanceAccountsModule),
        canActivate: [MsalGuard],
      },
      {
        path: 'finance/ledger',
        loadChildren: () => import('./finance-ledger/finance-ledger.module').then((m) => m.FinanceLedgerModule),
        canActivate: [MsalGuard],
      },
      {
        path: 'reports/checkbank',
        loadChildren: () => import('././crystalreports/check-bank/check-bank.module').then((m) => m.CheckBankModule),
        canActivate: [MsalGuard],
      },
      {
        path: 'cte/closing',
        component: FxrateComponent,
        canActivate: [MsalGuard],
      },
      {
        path: 'entities',
        loadChildren: () => import('./entities/entities/entities.module').then((m) => m.EntitiesModule),
        canActivate: [MsalGuard],
      },
      {
        path: 'entities/people',
        loadChildren: () => import('./entities/people/people.module').then((m) => m.PeopleModule),
        canActivate: [MsalGuard],
      },
      {
        path: 'entities/investors',
        loadChildren: () => import('./entities/investors/investors.module').then((m) => m.InvestorsModule),
        canActivate: [MsalGuard],
      },
      {
        path: 'entities/intermediaries',
        loadChildren: () => import('./entities/intermediaries/intermediaries.module').then((m) => m.IntermediariesModule),
        canActivate: [MsalGuard],
      },
      {
        path: 'entities/debtors',
        loadChildren: () => import('./entities/debtors/debtors.module').then((m) => m.DebtorsModule),
        canActivate: [MsalGuard],
      },
      {
        path: 'entities/creditors',
        loadChildren: () => import('./entities/creditors/creditors.module').then((m) => m.CreditorsModule),
        canActivate: [MsalGuard],
      },
      {
        path: 'entities/leasees',
        loadChildren: () => import('./entities/leasees/leasees.module').then((m) => m.LeaseesModule),
        canActivate: [MsalGuard],
      },
      {
        path: 'entities/suppliers',
        loadChildren: () => import('./entities/suppliers/suppliers.module').then((m) => m.SuppliersModule),
        canActivate: [MsalGuard],
      },
      {
        path: 'entities/credebtors',
        loadChildren: () => import('./entities/credebtor/credebtor.module').then((m) => m.CredebtorModule),
        canActivate: [MsalGuard],
      },
      ...profilePaths.map((profilePath) => ({
        path: `entities/originator/:originatorId`,
        loadChildren: () => import('./originator-profile-details/originator-profile-details.module').then((m) => m.OriginatorProfileDetailsModule),
        canActivate: [MsalGuard],
      })),
      {
        path: 'tte/asset',
        loadChildren: () => import('./entities/asset/asset.module').then((m) => m.AssetModule),
        canActivate: [MsalGuard],
      },
      {
        path: 'tte/amortisation',
        loadChildren: () => import('./amortisation/amortisation.module').then((m) => m.AmortisationModule),
        canActivate: [MsalGuard],
      },
      {
        path: 'tte/liquidated',
        loadChildren: () => import('./liquidated-position/liquidated-position.module').then((m) => m.LiquidatedPositionModule),
        canActivate: [MsalGuard],
      },
      {
        path: 'tte/contracts',
        loadChildren: () => import('./contracts/contracts.module').then((m) => m.ContractsModule),
        canActivate: [MsalGuard],
      },
      {
        path: 'reports/bankaudit',
        loadChildren: () => import('./crystalreports/bank-audit/bank-audit.module').then((m) => m.BankAuditModule),
        canActivate: [MsalGuard],
      },
      {
        path: 'uploadreports/upload',
        component: UploadsComponent,
        canActivate: [MsalGuard],
      },
      {
        path: 'dashboard',
        loadChildren: () => import('./dashboard/dashboard.module').then((m) => m.DashboardModule),
        canActivate: [MsalGuard],
      },
      {
        path: 'about',
        loadChildren: () => import('./about/about.module').then((m) => m.AboutModule),
        canActivate: [MsalGuard],
      },
      {
        path: 'component',
        loadChildren: () => import('./component/component.module').then((m) => m.ComponentsModule),
        canActivate: [MsalGuard],
      },
      {
        path: 'tte/transactions',
        loadChildren: () => import('./trade/trade-transactions-module').then((m) => m.TradeTransactionsModule),
        canActivate: [MsalGuard],
      },
      {
        path: 'tte/algorithms',
        loadChildren: () => import('./algorithms/algorithms.module').then((m) => m.AlgorithmsModule),
        canActivate: [MsalGuard],
        data: { group: 'tte' },
      },
      {
        path: 'cte/identification',
        loadChildren: () => import('./algorithms/algorithms.module').then((m) => m.AlgorithmsModule),
        canActivate: [MsalGuard],
        data: { group: 'cte' },
      },
      {
        path: 'tte/investors',
        loadChildren: () => import('./investors/investors.module').then((m) => m.InvestorsModule),
        canActivate: [MsalGuard],
      },
      {
        path: 'finance/models',
        loadChildren: () => import('./accounting/models/models.module').then((m) => m.ModelsModule),
        canActivate: [MsalGuard],
      },
      {
        path: 'originator/profiles',
        loadChildren: () => import('./originator-profiles/originator-profiles.module').then((m) => m.OriginatorProfilesModule),
        canActivate: [MsalGuard],
      },
     /* {
        path: 'originator/profiles/:originatorId/transactions',
        loadChildren: () => import('./originator-transactions/originator-transactions.module').then((m) => m.OriginatorTransactionsModule),
        canActivate: [MsalGuard],
      },*/
      {
        path: 'finance/reserves',
        loadChildren: () => import('./reserve-profile/reserve-profile.module').then((m) => m.ReserveProfileModule),
        canActivate: [MsalGuard],
      },
      {
        path: 'controls/data',
        loadChildren: () => import('./data-update/data-update.module').then((m) => m.DataUpdateModule),
        canActivate: [MsalGuard],
      },
      {
        path: 'exchange',
        loadChildren: () => import('./exchange/exchange.module').then((m) => m.ExchangeModule),
        canActivate: [MsalGuard],
      },
      {
        path: 'exceptions/tar',
        loadChildren: () => import('./tar-report/tar-report.module').then((m) => m.TarReportModule),
        canActivate: [MsalGuard],
      },
      {
        path: 'buy/assess',
        loadChildren: () => import('./assess/assess.module').then((m) => m.AssessModule),
        canActivate: [MsalGuard],
      },
      {
        path: 'buy/approve',
        loadChildren: () => import('./approval/approval.module').then((m) => m.ApprovalModule),
        canActivate: [MsalGuard],
      },
      {
        path: 'buy/authorise',
        loadChildren: () => import('./authorise/authorise.module').then((m) => m.AuthoriseModule),
        canActivate: [MsalGuard],
      },
      {
        path: 'buy/prepare',
        loadChildren: () => import('./prepare/prepare.module').then((m) => m.PrepareModule),
        canActivate: [MsalGuard],
      },
      {
        path: 'logs/etr/authorised',
        loadChildren: () => import('./authorised-etr/authorised-etr.module').then((m) => m.AuthorisedEtrModule),
        canActivate: [MsalGuard],
      },
      {
        path: 'cte/banks',
        loadChildren: () => import('./closing-bank-balances/closing-bank-balances.module').then((m) => m.ClosingBankBalancesModule),
        canActivate: [MsalGuard],
      },
    ],
  },
  {
    path: 'login',
    component: LoginComponent,
  },
  {
    path: '**',
    redirectTo: '/dashboard',
  },
];

@NgModule({
  imports: [RouterModule.forRoot(Approutes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}

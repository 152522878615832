import { Injectable } from '@angular/core';
import { HttpService } from 'src/app/services/http.service';
import { Observable } from 'rxjs/internal/Observable';
import { AssetQueryParams } from '../shared/interfaces/asset-query-params';
import { AssetItem, AssetResponse, Summary } from '../shared/interfaces/asset-response.interface';
import { EntityQueryParams } from '../shared/interfaces/entity-query-params';
import { EntityResponse, Originator } from '../shared/interfaces/entity-response.interface';
import { AssetsDistinctValuesResponse } from './asset/interfaces/assets-distinct-filter-values.interface';
import { AssetBatchesResponse } from '../shared/interfaces/asset-batches-response.interface';
import {
  EntityBulkDocumentsRequestInterface,
  EntityDocumentsRequestInterface
} from "./entities/common/interfaces/entity-documents-request.interface";

@Injectable({ providedIn: 'root' })
export class EntitiesHttpService extends HttpService {
  getEntities(params: Partial<EntityQueryParams>): Observable<EntityResponse> {
    const baseUrl = this.baseUrl + 'entity';
    return this.http.get<EntityResponse>(baseUrl, {
      params: this.queryCleaner(params),
    });
  }

  deleteOrganisationsData(id: any) {
    const baseUrl = this.baseUrl + 'organisations/' + id;
    return this.http.delete(baseUrl);
  }

  deletePeopleData(id: any) {
    const baseUrl = this.baseUrl + 'people/' + id;
    return this.http.delete(baseUrl);
  }

  bulkDeleteOrganisationsData(ids: number[]) {
    const baseUrl = `${this.baseUrl}organisations/bulk-delete`;
    return this.http.delete(baseUrl, { body: { ids } });
  }

  bulkDeletePeopleData(ids: number[]) {
    const baseUrl = `${this.baseUrl}people/bulk-delete`;
    return this.http.delete(baseUrl, { body: { ids } });
  }

  getAssetData(params: Partial<AssetQueryParams>): Observable<AssetResponse> {
    const baseUrl = this.baseUrl + 'asset';
    return this.http.get<AssetResponse>(baseUrl, {
      params: this.queryCleaner(params),
    });
  }

  getAssetBatches(params: Partial<any>): Observable<AssetBatchesResponse> {
    const baseUrl = this.baseUrl + 'assets-batch';
    return this.http.get<AssetBatchesResponse>(baseUrl, {
      params: this.queryCleaner(params),
    });
  }

  getEntityOriginators(): Observable<Originator[]> {
    const baseUrl = this.baseUrl + 'entity/originators';
    return this.http.get<Originator[]>(baseUrl);
  }

  getEntityCredebtors(): Observable<Originator[]> {
    const baseUrl = this.baseUrl + 'entity/credebtors';
    return this.http.get<Originator[]>(baseUrl);
  }

  getAssetFilterDistinctValues(): Observable<AssetsDistinctValuesResponse> {
    return this.http.get<AssetsDistinctValuesResponse>(`${this.baseUrl}asset/filter-distinct-values`);
  }

  getAssetsSummary(params: Partial<AssetQueryParams>): Observable<Summary> {
    return this.http.get<Summary>(`${this.baseUrl}asset/summary`, {
      params: this.queryCleaner(params),
    });
  }

  getAssetById(id: string) {
    const baseUrl = this.baseUrl + 'asset/' + id;
    return this.http.get<AssetItem>(baseUrl);
  }

  updateAsset(id: string, data: AssetItem) {
    const baseUrl = this.baseUrl + 'asset/' + id;
    return this.http.patch<AssetItem>(`${baseUrl}`, data);
  }

  addAsset(data: AssetItem) {
    const baseUrl = this.baseUrl + 'asset';
    return this.http.post<AssetItem>(`${baseUrl}`, data);
  }

  updateEntityDocuments(data: EntityDocumentsRequestInterface): Observable<any> {
    return this.http.patch<AssetItem>(`${this.baseUrl}entity/update-documents`, data);
  }

  updateEntityBulkDocuments(data: EntityBulkDocumentsRequestInterface[]): Observable<any> {
    return this.http.patch<AssetItem>(`${this.baseUrl}entity/bulk-update-documents`, data);
  }

  deleteAsset(id: string) {
    const baseUrl = this.baseUrl + 'asset/' + id;
    return this.http.delete(baseUrl);
  }

  getAllOwners(): Observable<EntityResponse> {
    const baseUrl = this.baseUrl + 'entity';
    return this.http.get<EntityResponse>(`${baseUrl}`, { params: { role: 'Owner' } });
  }
}

<div
class="modal fade show custom-model-top"
tabindex="-1"
role="dialog"
>
<div class="buy-asset-modal modal-dialog " role="document">
  <div class="modal-content">
    <div class="modal-header justify-content-between">
      <h5 class="modal-title">{{!isSecondForm?'Add Asset':'Add Trade'}}</h5>
      <button
        type="button"
        class="close"
        data-dismiss="modal"
        (click)="closeModal()"
      >
        <span>&times;</span>
      </button>
    </div>

    <div *ngIf="!isSecondForm" class="modal-body padding-model form-editing">
      <div class="row">
        <div class="col-lg-12">
          <div class="card w-100">
            <div class="card">
              <form [formGroup]="buyAssetForm" (ngSubmit)="onSubmit()">
                <div class="row">

                  <div class="width-217" >
                    <div class="mt-3">
                      <label for="creditorId" class="form-title text-nowrap"
                        > <span class="required">*</span> Creditor
                        <span
                          class="tooltip-ng ms-0"
                          placement="bottom"
                          ngbTooltip="{{ tooltipData.creditorId }}"
                          ><i class="bi bi-question-circle"></i>
                          </span> 
                      <a class="ms-3" href="javascript:void(0)" (click)="AddEntityModel()" >  Add Creditor</a>
                    </label>
                      <ng-select
                      class="custom"
                      [items]="creditorData"
                      [virtualScroll]="true"
                      bindLabel="name"
                      bindValue="id"
                      id="creditorId"
                      formControlName="creditorId"
                      (change)="onCreditorChange($event)"
                      >

                      <ng-template ng-label-tmp let-item="item">
                        {{ item.name }}
                      </ng-template>
                      <ng-template ng-option-tmp let-item="item">
                        <span [title]="item.name" tooltip="item.name">{{ item.name }}</span>
                      </ng-template>

                    </ng-select>
                    </div>
                  </div>


                  <div class="width-147">
                    <div class="mt-3">
                      <label for="name" class="form-title text-nowrap"><span class="required">*</span> Asset Name
                        <span
                        class="tooltip-ng ms-0"
                        placement="bottom"
                        ngbTooltip="{{ tooltipData.assetName }}"
                        ><i class="bi bi-question-circle"></i></span>
                      </label>
                   <ng-select
                      class="custom"
                      [items]="entityData"
                      [virtualScroll]="true"
                      [addTag]="addNewTag"
                      (change)="onEntitySelect($event,'entityId')"
                      bindLabel="name"
                      bindValue="id"
                      id="name"
                      formControlName="entityId">
                      <ng-template ng-label-tmp let-item="item">
                        {{ item.name }}
                      </ng-template>
                      <ng-template ng-option-tmp let-item="item">
                        <span [title]="item.name">{{ item.name }}</span>
                      </ng-template>
                  </ng-select>

                    </div>
                  </div>

                  <div class="width-120">
                    <div class="mt-3">
                      <label for="issuedDate" class="form-title text-nowrap"
                        > Issued
                        <span
                          class="tooltip-ng ms-0"
                          placement="bottom"
                          ngbTooltip="{{ tooltipData.issuedDate }}"
                          ><i class="bi bi-question-circle"></i></span
                      ></label>
                      <div class="input-group">
                        <input
                          class="form-control"
                          placeholder="YYYY-MM-DD"
                          name="name"
                          formControlName="issuedDate"
                          id="issuedDate"
                          ngbDatepicker
                          #d1="ngbDatepicker"
                        />
                        <button
                          class="btn btn-outline-secondary btn-calendar-icon"
                          (click)="d1.toggle()"
                          type="button"
                        >
                          <img alt="" src="./assets/images/icon/calendar-icon.svg" />
                        </button>
                      </div>
                    </div>
                  </div>

                  <div class="width-60">
                    <div class="mt-3">
                      <label for="ccy" class="form-title text-nowrap"><span class="required">*</span> Ccy
                        <span
                        class="tooltip-ng ms-0"
                        placement="bottom"
                        ngbTooltip="{{ tooltipData.ccy}}"
                        ><i class="bi bi-question-circle"></i></span>
                      </label>
                      <select class="custom-form-select" id="ccy" formControlName="ccy">
                        <option *ngFor="let cc of allCcyData" [value]="cc.name">{{ cc.name }}</option>
                      </select>
                    </div>
                  </div>

                  <div class="width-105 ms-1">
                    <div class="mt-3">
                      <label for="unitPrice" class="form-title text-nowrap"
                        ><span class="required">*</span>  Unit Price
                        <span
                        class="tooltip-ng ms-0"
                        placement="bottom"
                        ngbTooltip="{{ tooltipData.unitPrice }}"
                        ><i class="bi bi-question-circle"></i></span>
                      </label>
                      <input
                        type="text"
                        [appOnlyNumber]="true"
                        class="form-control text-left sd-width inputNumberText"
                        placeholder=""
                        formControlName="unitPrice"
                        id="unitPrice"
                        (blur)="handleNegativeValue('unitPrice','add-asset')"
                      />
                    </div>
                  </div>


                  <div class="width-45 ms-1">
                    <div class="mt-3">
                      <label for="quantity" class="form-title text-nowrap"
                        ><span class="required">*</span> Qty 
                        <span
                        class="tooltip-ng ms-0"
                        placement="bottom"
                        ngbTooltip="{{ tooltipData.quantity}}"
                        ><i class="bi bi-question-circle"></i></span>
                      </label>
                      <input
                        type="text"
                        appOnlyNumber
                        class="form-control sd-width inputNumberText"
                        placeholder=""
                        formControlName="quantity"
                        id="quantity"
                        (blur)="handleNegativeValue('quantity','add-asset')"
                      />
                    </div>
                  </div>
               
                  
                  <div class="width-71 ms-2">
                    <div class="mt-3">
                      <label for="vatIncluded" class="form-title text-nowrap"
                        ><span class="required">*</span> VAT
                        <span
                        class="tooltip-ng ms-0"
                        placement="bottom"
                        ngbTooltip="{{ tooltipData.vatIncluded}}"
                        ><i class="bi bi-question-circle"></i></span>
                      </label>
                        <div class="input-container">
                        <input
                        type="text"
                        appPercentNumber
                        class="form-control text-left sd-width inputNumberText"
                        placeholder=""
                        formControlName="vatIncluded"
                        id="vatIncluded"
                      />
                      <span>%</span>
                    </div>
                    </div>
                  </div>
                 
                  <div class="width-75">
                    <div class="mt-3">
                      <label for="vatOnPurchases" class="form-title text-nowrap ellipsis-vat-purchase"
                        ><span class="required">*</span> VAT P<span class="span-ellipsis">...</span>
                        <span
                        class="tooltip-ng ms-0"
                        placement="bottom"
                        ngbTooltip="{{ tooltipData.vatOnPurchases}}"
                        ><i class="bi bi-question-circle"></i></span>
                      </label>
                        <div class="input-container">
                        <input
                        type="text"
                        appPercentNumber
                        class="form-control text-left sd-width inputNumberText"
                        placeholder=""
                        formControlName="vatOnPurchases"
                        id="vatOnPurchases"
                      />
                      <span>%</span>
                    </div>
                    </div>
                  </div>

                  <div class="width-109 ms-1">
                    <div class="mt-3">
                      <label for="batch" class="form-title text-nowrap"><span class="required">*</span> Batch
                        <span
                        class="tooltip-ng ms-0"
                        placement="bottom"
                        ngbTooltip="{{ tooltipData.batch }}"
                        ><i class="bi bi-question-circle"></i></span>
                      </label>
                      <ng-select
                      class="custom custom-batch-dd"
                      [items]="batchesDataAssets"
                      [virtualScroll]="true"
                      [addTag]="addTagCapitalized"
                      bindLabel="name"
                      bindValue="id"
                      id="batch"
                      formControlName="batchParent"
                      [class.disabled]="batchDisabled"
                    >
                      <ng-template ng-label-tmp let-item="item">
                        {{ item.name }}
                      </ng-template>
                      <ng-template ng-option-tmp let-item="item">
                        <span [title]="item.name">{{ item.name }}</span>
                      </ng-template>
                    </ng-select>
                    </div>
                  </div>
    
                  <div class="width-119">
                    <div class="mt-3">
                      <label for="serialNumber" class="form-title text-nowrap"
                        ><span class="required">*</span> Serial Number
                        <span
                        class="tooltip-ng ms-0"
                        placement="bottom"
                        ngbTooltip="{{ tooltipData.serialNumber}}"
                        ><i class="bi bi-question-circle"></i></span>
                      </label>
                      <input
                        type="text"
                        class="form-control sd-width"
                        placeholder=""
                        formControlName="serialNumber"
                        id="serialNumber"
                      />
                    </div>
                  </div>

                  <div class="width-150 ms-1">
                    <div class="mt-3">
                      <label for="classification" class="form-title text-nowrap"
                        ><span class="required">*</span> Classification
                        <span
                        class="tooltip-ng ms-0"
                        placement="bottom"
                        ngbTooltip="{{ tooltipData.classification }}"
                        ><i class="bi bi-question-circle"></i></span>
                      </label>

                      <ng-select
                      class="custom custom-classification-dd"
                      [items]="classificationData"
                      [virtualScroll]="true"
                      bindLabel="name"
                      bindValue="id"
                      id="classification"
                      formControlName="classification"
                      >

                      <ng-template ng-label-tmp let-item="item">
                        {{ item.name }}
                      </ng-template>
                      <ng-template ng-option-tmp let-item="item">
                        <span [title]="item.name" tooltip="item.name">{{ item.name }}</span>
                      </ng-template>

                    </ng-select>

                    </div>
                  </div>

                  <div class="width-104 ms-1">
                    <div class="mt-3">
                      <label for="eot" class="form-title text-nowrap"
                        ><span class="required">*</span> EoT Value
                        <span
                        class="tooltip-ng ms-0"
                        placement="bottom"
                        ngbTooltip="{{ tooltipData.eot}}"
                        ><i class="bi bi-question-circle"></i></span>
                      </label>
                      <input
                        type="text"
                        [appOnlyNumberAllowZero]="true"
                        class="form-control sd-width inputNumberText"
                        placeholder=""
                        formControlName="eot"
                        id="eot"
                      />
                    </div>
                  </div>

                  <div class="width-98 ms-1">
                    <div class="mt-3">
                      <label for="repairMaintenance" class="form-title text-nowrap ellipsis-rm"
                        ><span class="required">*</span> Repair & Ma<span class="span-ellipsis">..</span>
                        <span
                        class="tooltip-ng ms-0"
                        placement="bottom"
                        ngbTooltip="{{ tooltipData.repairAndMaintenance}}"
                        ><i class="bi bi-question-circle"></i></span>
                      </label>
                      <input
                        type="text"
                        [appOnlyNumberAllowZero]="true"
                        class="form-control sd-width inputNumberText"
                        placeholder=""
                        formControlName="repairMaintenance"
                        id="repairMaintenance"
                      />
                    </div>
                  </div>

                  <div class="width-175 field-groups me-2">
                    <div class="mt-3">
                      <label for="file" class="form-title text-nowrap">
                        Document Upload
                        <span class="tooltip-ng ms-0" placement="bottom" ngbTooltip="{{ tooltipData.file }}">
                          <i class="bi bi-question-circle"></i>
                        </span>
                      </label>
                      <div class="form-group file-upload d-flex">
                        <input type="file" class="file-border upload_file" id="file"  #fileUploader
                        [disabled]="disableFileChange" (change)="fileChange($event)" accept=".pdf">

                        <div class="loader_gif" *ngIf="showUploadLoader">
                          <img alt="" width="20px" src="assets/images/icon/loader-1.gif" />
                        </div>

                        <img *ngIf="isFile_Selected && !showUploadLoader" class="ms-1 cursor-pointer" src="assets/images/icon/cross-icon.svg"
                          (click)="clearFileInput(fileUploader)" alt="" width="10px" />

                      </div>
                    </div>
                  </div>

                  <div class="width-299 height-0">
                    <div class="mt-3">
                      <label for="tb-fname" class="form-title text-nowrap"> Description
                        <span
                        class="tooltip-ng ms-0"
                        placement="bottom"
                        ngbTooltip="{{ tooltipData.description}}"
                        ><i class="bi bi-question-circle"></i></span>  
                      </label>
                      <textarea type="text" class="form-control height-80" placeholder="Write here description..." formControlName="description" row="3"
                        maxlength="512"> </textarea>
                    </div>
                  </div>
                  
                  <div class="width-120">
                    <div class="mt-3">
                      <label for="purchasedDate" class="form-title text-nowrap"
                        > Purchased
                        <span
                          class="tooltip-ng ms-0"
                          placement="bottom"
                          ngbTooltip="{{ tooltipData.purchasedDate }}"
                          ><i class="bi bi-question-circle"></i></span
                      ></label>
                      <div class="input-group">
                        <input
                          class="form-control"
                          placeholder="YYYY-MM-DD"
                          name="name"
                          formControlName="purchasedDate"
                          id="purchasedDate"
                          ngbDatepicker
                          #d2="ngbDatepicker"
                        />
                        <button
                          class="btn btn-outline-secondary btn-calendar-icon"
                          (click)="d2.toggle()"
                          type="button"
                        >
                          <img alt="" src="./assets/images/icon/calendar-icon.svg" />
                        </button>
                      </div>
                    </div>
                  </div>
                  
                
    
                  <div class="col-12 bottom-fixed">
                    <div class="d-flex justify-content-center mt-4">
                      <button type="button" (click)="openTrade()" class="btn btn-light me-3 btn-custom-footer">
                        Open Trade
                      </button>
                      <button [disabled]="buyAssetForm.invalid" type="button" (click)="addMore()" class="btn btn-light me-3 btn-custom-footer">
                        Add Another Asset
                      </button>

                      <button [disabled]="buyAssetForm.invalid" type="submit" class="btn btn-light me-3 btn-custom-footer">
                        Save
                      </button>
                      <button
                        type="button"
                        class="btn btn-light btn-custom-footer"
                        data-dismiss="modal"
                        (click)="closeModal()"
                      >
                        Cancel
                      </button>
                    </div>
                  </div>

           

                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    
    </div>

    <div  *ngIf="isSecondForm" class="modal-body padding-model form-editing">
      <div class="row">
        <div class="col-lg-12">
          <div class="card w-100">
            <div class="card">
              <form [formGroup]="buyAssetSecondForm" (ngSubmit)="onSubmitSecondForm()">
                <div class="row">

                  <div class="width-240">
                    <div class="mt-3">
                      <label for="" for="originatorId" class="form-title text-nowrap"
                        ><span class="required">*</span> Originator
                        <span
                          class="tooltip-ng ms-0"
                          placement="bottom"
                          ngbTooltip="{{ tooltipData.originatorId }}"
                          ><i class="bi bi-question-circle"></i></span
                      ></label>
                      <ng-select
                       [items]="originatorData"
                       [virtualScroll]="true"
                       bindLabel="name"
                       class="custom custom-originator-dd"
                       bindValue="id"
                       id="originatorId"
                       formControlName="originatorId"
                       >
                       <ng-template ng-label-tmp let-item="item">
                        {{ item.name }}
                      </ng-template>
                      <ng-template ng-option-tmp let-item="item">
                        <span [title]="item.name" tooltip="item.name">{{ item.name }}</span>
                      </ng-template>
                    </ng-select>
                    </div>
                  </div>

                  <div class="width-240" >
                    <div class="mt-3">
                      <label for="leaseeId" class="form-title text-nowrap"
                        ><span class="required">*</span> Leasee
                        <span
                          class="tooltip-ng ms-0"
                          placement="bottom"
                          ngbTooltip="{{ tooltipData.leaseeId }}"
                          ><i class="bi bi-question-circle"></i></span
                      ></label>
                      <ng-select
                      class="custom custom-leasee-dd"
                      [items]="leaseeData"
                      [virtualScroll]="true"
                      bindLabel="name"
                      bindValue="id"
                      id="leaseeId"
                      formControlName="leaseeId"
                      >
                      <ng-template ng-label-tmp let-item="item">
                        {{ item.name }}
                      </ng-template>
                      <ng-template ng-option-tmp let-item="item">
                        <span [title]="item.name" tooltip="item.name">{{ item.name }}</span>
                      </ng-template>

                    </ng-select>
                    </div>
                  </div>

                  
                  <div class="width-217">
                    <div class="mt-3">
                      <label for="name" class="form-title text-nowrap"
                        > Asset Name
                        <span
                          class="tooltip-ng ms-0"
                          placement="bottom"
                          ngbTooltip="{{ tooltipData.assetID }}"
                          ><i class="bi bi-question-circle"></i></span
                      ></label>
                      <ng-select
                          class="custom"
                          [items]="entityData"
                          [virtualScroll]="true"
                          bindLabel="name"
                          bindValue="id"
                          id="name"
                          formControlName="assetId"
                          (change)="onEntitySelect($event,'assetId')">
                      <ng-template ng-label-tmp let-item="item">
                        {{ item.name }}
                      </ng-template>
                      <ng-template ng-option-tmp let-item="item">
                        <span [title]="item.name" tooltip="item.name">{{ item.name }}</span>
                        </ng-template>
                    </ng-select>
                    </div>
                  </div>

                  <div class="width-109 ms-1">
                    <div class="mt-3">
                      <label for="batch" class="form-title text-nowrap"><span class="required">*</span> Batch Parent
                        <span
                        class="tooltip-ng ms-0"
                        placement="bottom"
                        ngbTooltip="{{ tooltipData.batch }}"
                        ><i class="bi bi-question-circle"></i></span>
                      </label>
                      <ng-select
                      class="custom custom-batch-dd"
                      [items]="batchesDataTrade"
                      [virtualScroll]="true"
                      [addTag]="addNewTag"
                      bindLabel="name"
                      bindValue="id"
                      id="batch"
                      formControlName="batchParent"
                      (change)="onBatchSelect($event)"
                    >
                      <ng-template ng-label-tmp let-item="item">
                        {{ item.name }}
                      </ng-template>
                      <ng-template ng-option-tmp let-item="item">
                        <span [title]="item.name">{{ item.name }}</span>
                      </ng-template>
                    </ng-select>
                    </div>
                  </div>

                  <div class="width-60">
                    <div class="mt-3">
                      <label for="ccy" class="form-title text-nowrap"><span class="required">*</span> Ccy
                        <span
                        class="tooltip-ng ms-0"
                        placement="bottom"
                        ngbTooltip="{{ tooltipData.quantity}}"
                        ><i class="bi bi-question-circle"></i></span>
                      </label>
                      <select class="custom-form-select" id="ccy" formControlName="ccy">
                        <option *ngFor="let cc of allCcyData" [value]="cc.name">{{ cc.name }}</option>
                      </select>
                    </div>
                  </div>
    
    
                  <div class="width-90">
                    <div class="mt-3">
                      <label for="deposit" class="form-title text-nowrap"
                        ><span class="required">*</span> Deposit
                        <span
                          class="tooltip-ng ms-0"
                          placement="bottom"
                          ngbTooltip="{{ tooltipData.deposit }}"
                          ><i class="bi bi-question-circle"></i></span
                      ></label>
                      <input
                        type="text"
                        [appOnlyNumber]="true"
                        class="form-control text-left ft-width inputNumberText"
                        placeholder=""
                        formControlName="deposit"
                        id="deposit"
                        (blur)="handleNegativeValue('deposit','add-trade')"
                      />
                    </div>
                  </div>

                  <div class="width-105">
                    <div class="mt-3">
                      <label for="faceValue" class="form-title text-nowrap"
                        ><span class="required">*</span> Face Value
                        <span
                          class="tooltip-ng ms-0"
                          placement="bottom"
                          ngbTooltip="{{ tooltipData.faceValue }}"
                          ><i class="bi bi-question-circle"></i></span
                      ></label>
                      <input
                        type="text"
                        [appOnlyNumber]="true"
                        class="form-control text-left ft-width inputNumberText"
                        placeholder=""
                        formControlName="faceValue"
                        id="faceValue"
                        (blur)="handleNegativeValue('faceValue','add-trade')"
                      />
                    </div>
                  </div>

                  <div class="width-65">
                    <div class="mt-3">
                      <label for="type" class="form-title"
                        > <span class="required">*</span> Type
                        <span
                          class="tooltip-ng ms-0"
                          placement="bottom"
                          ngbTooltip="{{ tooltipData.type }}"
                          ><i class="bi bi-question-circle"></i></span
                      ></label>
                      <select class="custom-form-select border-radius-100" id="type" formControlName="type">
                        <option *ngFor="let cc of typeData" [value]="cc.name">{{ cc.name }}</option>
                      </select>
                    </div>
                  </div>

                  <div class="width-70 ms-1">
                    <div class="mt-3">
                      <label for="ptv" class="form-title text-nowrap" > PTV
                        <span
                          class="tooltip-ng ms-0"
                          placement="bottom"
                          ngbTooltip="{{ tooltipData.purchasedValue }}"
                          ><i class="bi bi-question-circle"></i></span
                      ></label>
                      <div class="d-flex">
                      <input
                        type="text"
                        appPercentNumber
                        class="form-control text-left ft-width inputNumberText"
                        placeholder=""
                        formControlName="ptv"
                        id="ptv"
                      /><span class="ms-1">%</span>
                    </div>
                    </div>
                  </div>

                  <div class="width-71 me-1">
                    <div class="mt-3">
                      <label for="sellRate" class="form-title text-nowrap"
                        > Sell Rate
                        <span
                          class="tooltip-ng ms-0"
                          placement="bottom"
                          ngbTooltip="{{ tooltipData.sellRate }}"
                          ><i class="bi bi-question-circle"></i></span
                      ></label>
                      <div class="input-container">
                      <input
                        type="text"
                        appPercentNumber
                        class="form-control text-left ft-width inputNumberText"
                        placeholder=""
                        formControlName="sellRate"
                        id="sellRate"
                        maxlength="10"
                      />
                      <span>%</span>
                    </div>
                    </div>
                  </div>

                  <div class="width-83">
                    <div class="mt-3">
                      <label for="sellType" class="form-title"
                      > Sell Type
                        <span
                          class="tooltip-ng ms-0"
                          placement="bottom"
                          ngbTooltip="{{ tooltipData.sellType }}"
                        ><i class="bi bi-question-circle"></i></span
                        ></label>
                      <input
                        type="text"
                        class="form-control text-left ft-width"
                        placeholder=""
                        formControlName="sellType"
                        id="sellType"
                      />
                    </div>
                  </div>

                  <div class="width-45" >
                    <div class="mt-3">
                      <label for="months" class="form-title text-nowrap"
                        > Mos
                        <span
                          class="tooltip-ng ms-0"
                          placement="bottom"
                          ngbTooltip="{{ tooltipData.months }}"
                          ><i class="bi bi-question-circle"></i></span
                      ></label>
                      <input
                        type="number"
                        class="form-control text-left ft-width inputNumberText"
                        placeholder=""
                        formControlName="months"
                        id="months"
                        maxlength="2"
                        (blur)="handleNegativeValue('months','add-trade')"
                      />
                    </div>
                    </div>
                  
                    <div class="col-12 bottom-fixed">
                      <div class="d-flex justify-content-center mt-4">
    
                        <button [disabled]="buyAssetSecondForm.invalid" type="submit" class="btn btn-light me-3 btn-custom-footer">
                          Save
                        </button>
                        <button
                          type="button"
                          class="btn btn-light btn-custom-footer"
                          data-dismiss="modal"
                          (click)="closeModal()"
                        >
                          Cancel
                        </button>
                      </div>
                    </div>
                  
 
                </div>
             
              </form>
            </div>
          </div>
        </div>
      </div>
    
    </div>

  </div>
</div>
</div>
import { HttpService } from '../../../../services/http.service';
import { Observable } from 'rxjs/internal/Observable';
import { Injectable } from '@angular/core';
import { data } from "autoprefixer";
import { EntityDocumentsRequestInterface } from "../interfaces/entity-documents-request.interface";
import { AssetItem } from "../../../../shared/interfaces/asset-response.interface";

@Injectable({ providedIn: 'root' })
export class EntitiesHttpService extends HttpService {
  createEntity(data: any): Observable<any> {
    return this.http.post<Observable<any>>(`${this.baseUrl}entity`, data);
  }

  updateEntity(payload: {entityId: number, data: any}): Observable<any> {
    return this.http.patch(`${this.baseUrl}entity/${payload.entityId}`, payload.data);
  }

  updateEntityAddress(payload: { entityId: number; data: any }): Observable<any> {
    return this.http.patch(`${this.baseUrl}entity/address/${payload.entityId}`, payload.data);
  }

  updateEntityProperties(payload: { entityId: number; data: any }): Observable<any> {
    return this.http.patch(`${this.baseUrl}entity/properties/${payload.entityId}`, payload.data);
  }

  createEntityBanksAccounts(payload: { entityRoleId: string; data: any }): Observable<any> {
    return this.http.post(`${this.baseUrl}entity-banks/accounts/${payload.entityRoleId}`, payload.data);
  }

  updateEntityBanksAccounts(payload: { accountId: number; data: any }): Observable<any> {
    return this.http.patch(`${this.baseUrl}entity-banks/accounts/${payload.accountId}`, payload.data);
  }

  getBanksList(): Observable<any> {
    return this.http.get(`${this.baseUrl}entity-banks`);
  }

  updateEntityDocuments(data: EntityDocumentsRequestInterface): Observable<any> {
    return this.http.patch<AssetItem>(`${this.baseUrl}entity/update-documents`, data);
  }
}

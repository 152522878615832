import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { EditUploadsService } from '../../../uploads/edit-uploads.service';
import { TOOLTIP_DATA_ASSET } from 'src/app/shared/constants/helpers.const';
import { debounceTime, Subscription } from 'rxjs';
import { AdvancedNumberValueFormatter } from 'src/app/shared/helpers/number-formatter.helper';
import { EntitiesHttpService } from '../../entities-http.service';
import { confirmMessage, successMessage } from 'src/app/shared/helpers/messages-popup';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { SelectItem } from 'src/app/shared/interfaces/select-item.interface';
import { TradeHttpService } from 'src/app/trade/services/trade-http.service';
import { AssetItem, AssetResponse } from 'src/app/shared/interfaces/asset-response.interface';
import Swal from 'sweetalert2';
import { AssessHttpService } from 'src/app/assess/common/services/assess-http.service';
import { AddCreditorComponent } from '../add-creditor/add-creditor.component';
import * as moment from 'moment';
import { EntityResponse } from 'src/app/shared/interfaces/entity-response.interface';
import { AssetsSharedService } from '../assets-shared.service';
@Component({
  selector: 'app-buy-asset',
  templateUrl: './buy-asset.component.html',
  styleUrls: ['./buy-asset.component.scss'],
})
export class BuyAssetComponent implements OnInit, OnDestroy {
  buyAssetForm: any = FormGroup;
  buyAssetSecondForm: any = FormGroup;
  assetFormMultiple: any = FormGroup;
  tooltipData = TOOLTIP_DATA_ASSET;
  isSubmitted = false;
  allCcyData: SelectItem[] = [];
  private subscriptions: Subscription[] = [];
  creditorData: SelectItem[] = [];
  leaseeData: SelectItem[] = [];
  originatorData: SelectItem[] = [];
  entityData: { id: string; name: string; sortingName: string; }[] = [];
  entityDataTemp: { id: string; name: string; sortingName: string; }[] = [];
  classificationData: SelectItem[] = [];
  batchesDataAssets: { id: string; name: string; sortingName: string; disabled:boolean}[] = [];
  batchesDataAssetsTemp: { id: string; name: string; sortingName: string;disabled:boolean }[] = [];
  batchesDataTrade: SelectItem[] = [];
  batchesDataTempTrade: SelectItem[] = [];
  savedAssetIds: SelectItem[] = [];
  assetData: AssetItem[]= [];
  typeData: SelectItem[]= [{ name: 'a-ETR', id: 'a' }, { name: 'f-ETR', id: 'f' }];
  @Input() multiple: boolean;
  @Input() show: boolean;
  @Output() closeDialogEvent = new EventEmitter<boolean>();
  @Output() createUpdateEvent = new EventEmitter<boolean>();
  isAddMore: boolean;
  batchDisabled= false;
  isSecondForm: boolean;
  showUploadLoader = false;
  disableFileChange = true;
  isFile_Selected = false;
  isShowAddEditEntityDialog = false;
  distinctValues: any;
  constructor(
    private entitiesHttpService: EntitiesHttpService,
    public editUploadsService: EditUploadsService,
    public activeModal: NgbActiveModal,
    private tradeTransactionsHttpService: TradeHttpService,
    private assessHttpService: AssessHttpService,
    private modalService: NgbModal,
    private assetsSharedService: AssetsSharedService
  ) { }

  ngOnInit(): void {
    this.buyAssetForm = this.editUploadsService.buyAssetForm;
    this.subscribeToValueChanges();
    this.subscribeTobatch();
    this.subscribeToAssetName();
    this.getDrpData();
  }

  getDistinctValues() {
    this.entitiesHttpService.getFilterDistinctValues().subscribe((response) => {
      this.distinctValues = response;
    });
  }

  get errorControl() {
    return this.buyAssetForm.controls;
  }

  getDrpData() {
    this.getCreditors();
    this.tradeTransactionsHttpService.getLeasees().subscribe((response: any) => {
      this.leaseeData = response.map((value: any) => ({
        id: value.id,
        name: `${value.id}-${value.name}`,
        sortingName: `${value.name}`,
      }));
      this.leaseeData = this.sortByName(this.leaseeData);
    });

    this.tradeTransactionsHttpService.getOriginators().subscribe((response: any) => {
      this.originatorData = response.map((value: any) => ({
        id: value.id,
        name: `${value.id}-${value.name}`,
        sortingName: `${value.name}`,
      }));
      this.originatorData = this.sortByName(this.originatorData);
    });

    this.getBatches();
    const queryParamsAssets = { pageIndex: 0, perPage: 10000, sort: 'name' };
    this.entitiesHttpService.getAssetData(queryParamsAssets).subscribe((response: AssetResponse) => {
      this.assetData = response.items.map((item: AssetItem) => ({ ...item }));
      this.classificationData = this.sortByName(this.uniqueItems(response.items, 'classification'));
    });
    this.getEntities();
    this.entitiesHttpService.getFilterDistinctValues().subscribe((response) => {
      this.distinctValues = response;
    });

    this.loadCcyData();
  }

  processBatchesData = (data: any[], includeDisabled = false) => {
    return data.map((value: any) => ({
      [`id`]: value,
      [`name`]: `${value}`,
      [`sortingName`]: `${value}`,
      ...(includeDisabled ? { disabled: true } : {}),
    }));
  };

  getBatches(){
    this.tradeTransactionsHttpService.getAssetFilterDistinctValues().subscribe((response: any) => {
      this.batchesDataAssets = this.sortByName(this.processBatchesData(response?.batches, true));
      this.batchesDataAssetsTemp = this.batchesDataAssets;
      this.batchesDataTrade = this.sortByName(this.processBatchesData(response?.batches));
      this.batchesDataTempTrade = this.batchesDataTrade;
    });
  }

  getEntities(): void {
    const queryParamsEntities = { pageIndex: 0, perPage: 10000, role: 'Asset' };
    this.entitiesHttpService.getEntities(queryParamsEntities).subscribe({
      next: (response: EntityResponse) => {
        this.entityData = response?.items.map((item) => ({
          id: item.id.toString(),
          name: item.name,
          sortingName: item.name,
        }));
        this.entityData.push({ id: 'Multiple', name: 'Multiple',sortingName:'Multiple' });
        this.entityData = this.sortByName(this.entityData);
        this.entityDataTemp = [...this.entityData];
      },
    });
  }

  onEntitySelect(event: any, formControlName: string) {
    if (event == undefined && formControlName == 'assetId' && this.assetData) {
      this.buyAssetSecondForm.patchValue({
        batchParent: null,
      });
    }
    else if (event && formControlName == 'entityId') {
      this.buyAssetForm.patchValue({
        name: event.name, id: event.id || null
      });
    }
  }

  onBatchSelect(event: any) {
    if (event == undefined && this.assetData) {
      this.buyAssetSecondForm.patchValue({
        assetId: null,
      });
    }
    else if (event  && this.assetData.length > 0) {
      const selectedData: any = this.assetData.filter((item) => item.batchParent === event.name);
      if(selectedData.length == 1){
        this.buyAssetSecondForm.patchValue({
          assetId: selectedData[0].entityId ? selectedData[0].entityId.toString() : null,
        });
      }
      else if(selectedData.length > 1){
        this.buyAssetSecondForm.patchValue({
          assetId: 'Multiple',
        });
      }
    }
  }

  getCreditors(){
    this.tradeTransactionsHttpService.getCreditors().subscribe((response: any) => {
      this.creditorData = response.map((value: any) => ({
        id: value.id,
        name: `${value.id}-${value.name}`,
        sortingName: `${value.name}`,
      }));
      this.creditorData = this.sortByName(this.creditorData);
    });
  }

  private uniqueItems(items: any[], key: string) {
    const seen = new Set();
    return items
      .map((item) => ({
        id: item[key],
        name: `${item[key]}${key === 'assetId' ? `-${item.name}` : ''}`,
        sortingName: item[key === 'assetId' ? 'name' : key],
      }))
      .filter((item) => !seen.has(item.id) && seen.add(item.id));
  }

  private sortByName(items: any[]) {
    return items.sort((a, b) => {
      const nameA = a.sortingName?.trim().toLowerCase() || '';
      const nameB = b.sortingName?.trim().toLowerCase() || '';
      return nameA.localeCompare(nameB, undefined, { numeric: true, sensitivity: 'base' });
    });
  }

  loadCcyData() {
    this.entitiesHttpService.getCurrencyWithBank().subscribe((response: any) => {
      response.forEach((items: any) => {
        this.allCcyData.push({
          id: items.currency,
          name: items.currency,
        });

      });
      this.allCcyData = [...this.allCcyData];
    });
  }

  onSubmit() {
    this.isSubmitted = true;
    if (this.buyAssetForm.invalid) return;
    const fieldsToFormat = ['issuedDate','purchasedDate'];
    const rawData = { ...this.buyAssetForm.value };
    rawData.vatIncluded = rawData.vatIncluded == '0.000' ? '0' : (parseFloat(rawData.vatIncluded) / 100).toString();
    rawData.vatOnPurchases = rawData.vatOnPurchases == '0.000' ? 0 : parseFloat(rawData.vatOnPurchases) / 100;
    rawData.eot = +rawData.eot;
    rawData.unitPrice = +rawData.unitPrice;
    rawData.quantity = +rawData.quantity;
    delete rawData.file;
    fieldsToFormat.forEach((field) => {
      const date = rawData[field];
      if (date) {
        rawData[field] = field === 'months' ? moment(date).format('MM') : moment(`${date.year}-${date.month}-${date.day}`, 'YYYY-MM-DD').format('YYYY-MM-DD');
      }
    });
    if (!this.entityDataTemp.some((item: any) => item.id === rawData.entityId)) {
      rawData.entityId = null;
    }
    this.addAsset(rawData);
  }

  onSubmitSecondForm() {
    this.isSubmitted = true;
    if (this.buyAssetSecondForm.invalid) return;
    const fieldsToFormat = ['tradedDate'];
    const rawData = { ...this.buyAssetSecondForm.value };
    rawData.deposit = +rawData.deposit;
    rawData.faceValue = +rawData.faceValue;
    rawData.ptv = +rawData.ptv / 100;
    rawData.sellRate = +rawData.sellRate / 100;
    delete rawData.assetId;
    fieldsToFormat.forEach((field) => {
      const date = rawData[field];
      if (date) {
        rawData[field] = field === 'months' ? moment(date).format('MM') : moment(`${date.year}-${date.month}-${date.day}`, 'YYYY-MM-DD').format('YYYY-MM-DD');
      }
    });
    this.addTrade(rawData);
  }

  addAsset(data: any): void {
    this.entitiesHttpService.addAsset(data).subscribe({
      next: (response: any) => {
        this.getEntities();
        this.savedAssetIds.push({ id: response.entityId, name: response.name });
        if (this.isAddMore) {
          successMessage(`Asset Successfully Added.`);
          this.buyAssetForm.reset();
          this.buyAssetForm.patchValue({
            batchParent:response.batchParent
          });
          this.isAddMore = false;
          this.isSecondForm = false;
        }
        else {
          this.getBatches();
          successMessage(`Asset Successfully Added.`);
          this.buyAssetForm.reset();
          this.buyAssetSecondForm = this.editUploadsService.buyAssetSecondForm;
          this.buyAssetSecondForm.patchValue({
            batchParent:data.batchParent,
          });
            if( this.savedAssetIds.length == 1){
            this.buyAssetSecondForm.patchValue({ 
              assetId: this.savedAssetIds[0]?.id ? this.savedAssetIds[0].id.toString() : null,
            });
            }
            else if (this.savedAssetIds.length > 1) {
              this.buyAssetSecondForm.patchValue({
                assetId: 'Multiple',
              });
            }
          this.isSecondForm = true;
        }
      },
    });
  }

  addTrade(data: any): void {
    this.tradeTransactionsHttpService.addTrade(data).subscribe({
      next: (response: any) => {
        this.closeModal();
        successMessage(`Trade Successfully Added.`);
        this.buyAssetSecondForm.reset();
        this.assetsSharedService.emitCloseDialogEvent();
      },
    });
  }

  addMore() {
    this.isAddMore = true;
    this.batchDisabled = true;
    this.onSubmit();
  }

  openTrade() {
    this.isSecondForm = true;
    this.buyAssetSecondForm = this.editUploadsService.buyAssetSecondForm;
  }

  onOpenCalendar(container: any) {
    container.monthSelectHandler = (event: any): void => {
      container._store.dispatch(container._actions.select(event.date));
    };
    container.setViewMode('month');
  }

  closeModal() {
    this.activeModal.dismiss();
    this.buyAssetForm.reset();
    this.buyAssetSecondForm.reset();
  }
  closeEntityModal(){
    this.isShowAddEditEntityDialog = false;
  }
  private subscribeToValueChanges(): void {
    const fields = [
      { field: 'vatIncluded', decimal: 3 },
      { field: 'vatOnPurchases', decimal: 3 },
    ];
    fields.forEach(({ field, decimal }) => {
      const subscription = this.buyAssetForm
        .get(field)
        ?.valueChanges.pipe(debounceTime(500))
        .subscribe((value: any) => {
          const formattedValue = AdvancedNumberValueFormatter({ number: value, decimal });
          this.buyAssetForm.get(field)?.patchValue(formattedValue, { emitEvent: false });
        });
      if (subscription) {
        this.subscriptions.push(subscription);
      }
    });
  }

  private subscribeTobatch(): void {
    const batchSubscription = this.buyAssetForm.get('batchParent')?.valueChanges.pipe(
      debounceTime(300)
    ).subscribe((value: any) => {
      if (value && !this.batchesDataAssets.some(tag => tag.id === value)) {
        const newTag = { id: value, name: value,sortingName:value,disabled:false };
        this.batchesDataAssets = [...this.batchesDataAssets, newTag];
      }
      else{
        this.batchesDataAssets = [...this.batchesDataAssetsTemp];
      }
    });
    if (batchSubscription) {
      this.subscriptions.push(batchSubscription);
    }
  }

  private subscribeToAssetName(): void {
    const assetSubscription = this.buyAssetForm.get('entityId')?.valueChanges.pipe(
      debounceTime(300)
    ).subscribe((value: any) => {
      if (value && !this.entityData.some(tag => tag.id === value)) {
        const newTag = { id: value, name: value,sortingName:value };
        this.entityData = [...this.entityData, newTag];
        this.buyAssetForm.get('name')?.setValue(newTag.name);
      }
      else{
        this.entityData = [...this.entityDataTemp];
      }
    });
    if (assetSubscription) {
      this.subscriptions.push(assetSubscription);
    }
  }

  handleNegativeValue(controlName: string,form:any): void {
    const formGroup = form === 'add-asset' ? this.buyAssetForm : form === 'add-trade' ? this.buyAssetSecondForm : null;
    const control = formGroup?.get(controlName);
    if (control) {
      const value = Number(String(control.value).replace(/-/g, ''));
      control.setValue(isNaN(value) ? '' : value);
    }
  }

  addNewTag(tagName: string): any {
    const capitalizedTag = tagName.toUpperCase();
    return {
      id: capitalizedTag,
      name: capitalizedTag,
      disabled: false
    };
  }

  addTagCapitalized(tagName: string): any {
    const capitalizedTag = tagName.toUpperCase();
    return {
      id: capitalizedTag,
      name: capitalizedTag,
      disabled: false
    };
  }

  disableExistingOptions = (item: any) => true;
  onCreditorChange(event: any) {
    this.disableFileChange = !event?.id;
    this.isFile_Selected=false;
  }

  fileChange(element: any): void {
    const maxSize = 10000000; // 10 MB
    const allowedFileType = 'application/pdf';
    const file = element.target.files[0];
    if (!file) {
      this.showUploadLoader = false;
      element.target.value = '';
      return;
    }

    if (file.type !== allowedFileType) {
      element.target.value = '';
      Swal.fire({
        icon: 'error',
        text: 'Validation failed: Only PDF files are allowed.',
        showConfirmButton: true,
        timer: 5000,
      });
      return;
    }

    if (file.size > maxSize) {
      element.target.value = '';
      Swal.fire({
        icon: 'error',
        text: 'Validation failed: File size must be less than 10 MB.',
        showConfirmButton: true,
        timer: 5000,
      });

      return;
    }
    this.showUploadLoader=true;
    const creditorId = this.buyAssetForm.get('creditorId')?.value
    const renamedFile = new File(
      [file],
      `${creditorId}-${'file'}.pdf`,
      { type: file.type, lastModified: file.lastModified }
    );

    this.assessHttpService.uploadFile(renamedFile).subscribe(
      (response: any) => {
        this.buyAssetForm.get('file')?.setValue(response?.id);
        this.showUploadLoader = false;
        this.isFile_Selected = true;
      },
      (error) => {
        let errorMessage = error.error.message;
        if (Array.isArray(error)) {
          errorMessage = this.generateStringFromArrayWithLineBreak(error);
        }
        Swal.fire({
          icon: 'error',
          text: `${errorMessage}`,
          showConfirmButton: true,
          timer: 5000,
        });
        element.target.value = '';
        this.buyAssetForm.get('file')?.setValue('');
        this.showUploadLoader = false;
        this.isFile_Selected = false;
      }
    );
  }

  generateStringFromArrayWithLineBreak(array: string[]): string {
    let result = '';
    array.forEach((item, index) => {
      result += item;
      if (index < array.length - 1) {
        result += '<hr></br>';
      }
    });
    return result;
  }

  clearFileInput(fileUploader: HTMLInputElement): void {
    let docId = '';
    confirmMessage(' ').then((result) => {
      if (result.isConfirmed) {
        fileUploader.value = '';
        this.isFile_Selected = false;
        docId = this.buyAssetForm.get('file')?.value;
        this.assessHttpService.deleteUploadedFile(docId).subscribe((res=>{
          this.buyAssetForm.get('file')?.setValue('')
        })
        );
      }
    });
  }

  AddEntityModel(){
    this.isShowAddEditEntityDialog=true;
    const modalRef = this.modalService.open(AddCreditorComponent, {
      backdrop: true,
      windowClass: 'add-creditor-custom-modal',
    });
    modalRef.componentInstance.distinctValues = this.distinctValues;
    modalRef.result.then((result) => {
      if (result) {
      const creditorId = result?.entityRoles[0]?.id;
      this.buyAssetForm.get('creditorId')?.setValue(creditorId);
        this.getCreditors();
      }
    })
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach((sub) => sub.unsubscribe());
    this.subscriptions = [];
  }
}

import { Directive, ElementRef, forwardRef, HostListener, Input, Renderer2 } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

@Directive({
  selector: '[appOnlyNumberAllowZero]',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => OnlyNumberAllowZeroDirective),
      multi: true,
    },
  ],
})
export class OnlyNumberAllowZeroDirective implements ControlValueAccessor {
  @Input() appOnlyNumberAllowZero: boolean | '' = false;
  @Input() isFx: boolean | '' = false;
  @Input() allowedSign: string | 'none' = '';
  private onChange!: (val: string | number) => void;
  private onTouched!: () => void;
  private value!: string | number;

  constructor(
    private elementRef: ElementRef,
    private renderer: Renderer2,
  ) { }

  @HostListener('input', ['$event.target.value'])
  onInputChange(value: string) {
    const filteredValue: string | number = filterValue(value, this.allowedSign);
    const normalizedValue =
      filteredValue === '0' || /^0+$/.test(filteredValue.toString())
        ? filteredValue
        : filteredValue;
    this.updateTextInput(normalizedValue, this.value !== normalizedValue);
  }

  @HostListener('blur')
  onBlur() {
    if (this.appOnlyNumberAllowZero) {
      this.onValueFormating();
    }
  }

  @HostListener('focus')
  onFocus() {
    if (!this.value) {
      return;
    }
    this.updateTextInput(isNaN(ConvertISONumberToNormal(this.value as string)) ? this.value : ConvertISONumberToNormal(this.value as string), false);
  }

  private updateTextInput(value: string | number, propagateChange: boolean) {
    this.renderer.setProperty(this.elementRef.nativeElement, 'value', value);
    if (propagateChange) {
      this.onChange(value);
    }
    this.value = value;
  }

  onValueFormating() {
    if (!this.value) {
      return;
    }
    const formattedValue = SimpleNumberValueFormatter(this.value, this.isFx ? 4 : 2);
    if (formattedValue !== null && formattedValue !== undefined) {
      this.updateTextInput(formattedValue, false);
    }
  }

  registerOnChange(fn: (val: string | number) => void): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: () => void): void {
    this.onTouched = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    this.renderer.setProperty(this.elementRef.nativeElement, 'disabled', isDisabled);
  }

  writeValue(value: any): void {
    value = value
      ? this.appOnlyNumberAllowZero
        ? SimpleNumberValueFormatter(value, this.isFx ? 4 : 2)
        : String(value)
      : '';
    this.updateTextInput(value, false);
  }
}

function filterValue(value: string, allowedSign = ''): string | number {
  const regex = allowedSign ? new RegExp(`[^0-9\\${allowedSign}]`, 'g') : /[^0-9.]/g;
  value = value.replace(regex, '');
  if (allowedSign && value.includes(allowedSign)) {
    const firstOccurrenceIndex = value.indexOf(allowedSign);
    value =
      value.slice(0, firstOccurrenceIndex + 1) +
      value.slice(firstOccurrenceIndex + 1).replace(new RegExp(`\\${allowedSign}`, 'g'), '');
  }
  const decimalIndex = value.indexOf('.');
  if (decimalIndex !== -1) {
    value =
      value.slice(0, decimalIndex + 1) +
      value.slice(decimalIndex + 1).replace(/\./g, '');
  }
  if (value.startsWith('0') && !value.startsWith('0.')) {
    value = value.replace(/^0+/, '');
  }
  return value === '' ? '0' : value;
}

const SimpleNumberValueFormatter = (number: any, decimal = 2): string => {
  if (number === null || number === undefined) {
    return '';
  }
  const originalValue = number.toString().trim();
  if (/^0+\.?0*$/.test(originalValue)) {
    return '0';
  }
  const parsedValue = parseFloat(originalValue);
  if (isNaN(parsedValue)) {
    return '';
  }
  return parsedValue.toLocaleString('en-US', {
    minimumFractionDigits: decimal,
    maximumFractionDigits: decimal,
  });
};

const ConvertISONumberToNormal = (isoNumber: string | number): number => {
  if (typeof isoNumber === 'string') {
    const cleanNumber = isoNumber.replace(/,/g, '');
    return +cleanNumber;
  }
  return isoNumber;
};

<div
class="modal fade show custom-model-top"
tabindex="-1"
role="dialog"
>
<div class="add-creditor-model modal-dialog " role="document">
  <div class="modal-content">
    <div class="modal-header justify-content-between">
      <h5 class="modal-title">Add Creditor</h5>
      <button
        type="button"
        class="close"
        data-dismiss="modal"
        (click)="closeModal()"
      >
        <span>&times;</span>
      </button>
    </div>

    <div  class="modal-body padding-model form-editing">
   
      <div class="row p-1">
        <div class="col-lg-12 col-xl-12">
          <div class="card w-100">
            <div class="card">
              <form
                *ngIf="entityForm"
                [formGroup]="entityForm"
                (ngSubmit)="onSubmit()"
              >
                <div class="row">
                  <div class="width-158" style="z-index: 100001;">
                    <div class="mt-3">
                      <label for="type" class="form-title">
                        <span class="required">*</span>Type</label>
                      <ng-select class="custom"
                                 id="type"
                                 [style.flex]="1"
                                 [items]="typeList"
                                 bindLabel="name"
                                 bindValue="id"
                                 placeholder="All"
                                 formControlName="type">
                      </ng-select>
                    </div>
                    <app-form-error-component [control]="entityForm.controls['type']" />
                  </div>
                  <div class="width-120">
                    <div class="mt-3">
                      <label for="roleId" class="form-title">
                        <span class="required">*</span>Role</label>
                          <input
                          type="text"
                          class="form-control text-left sd-width"
                          placeholder=""
                          formControlName="roleId"
                          id="roleId"
                          [readonly]="true"
                        />

                    </div>
                    <app-form-error-component [control]="entityForm.controls['roleId']" />
                  </div>
      
                  <div class="width-175">
                    <div class="mt-3">
                      <div class="d-flex justify-content-between">
                        <label for="name" class="form-title"><span class="required">*</span>Name</label>
                        <div class="d-flex">
                          <label for="previousName" class="form-title me-2">Previous</label>
                          <input formControlName="previousName" id="previousName" type="checkbox">
                        </div>
                      </div>
                    </div>
                    <input
                      id="name"
                      type="text"
                      class="form-control ft-width"
                      placeholder=""
                      formControlName="name"
                    />
                    <app-form-error-component [control]="entityForm.controls['name']" />
                  </div>
      
                  <div class="width-158">
                    <div class="mt-3">
                      <label for="tradingAs" class="form-title">Trading As</label>
                    </div>
                    <input
                      id="tradingAs"
                      type="text"
                      class="form-control ft-width"
                      placeholder=""
                      formControlName="tradingAs"
                    />
                    <app-form-error-component [control]="entityForm.controls['tradingAs']" />
                  </div>
      
                  <div class="width-110">
                    <div class="mt-3">
                      <label for="email" class="form-title">Email</label>
                      <input
                        id="email"
                        type="text"
                        class="form-control ft-width"
                        placeholder=""
                        formControlName="email"
                      />
                    </div>
                    <app-form-error-component [control]="entityForm.controls['email']" />
                  </div>
      
                  <div class="width-130">
                    <div class="mt-3">
                      <label for="address1" class="form-title">
                        <span class="required">*</span>Address 1</label>
                      <input
                        id="address1"
                        type="text"
                        class="form-control ft-width"
                        placeholder=""
                        formControlName="address1"
                      />
                    </div>
                    <app-form-error-component [control]="entityForm.controls['address1']" />
                  </div>
      
                  <div class="width-130">
                    <div class="mt-3">
                      <label for="address2" class="form-title">Address 2</label>
                      <input
                        id="address2"
                        type="text"
                        class="form-control ft-width"
                        placeholder=""
                        formControlName="address2"
                      />
                    </div>
                    <app-form-error-component [control]="entityForm.controls['address2']" />
                  </div>
      
                  <div class="width-120">
                    <div class="mt-3">
                      <label for="city" class="form-title">
                        <span class="required">*</span>City</label>
                      <input
                      id="city"
                      type="text"
                      class="form-control ft-width"
                      placeholder=""
                      formControlName="city"
                    />
                    </div>
                    <app-form-error-component [control]="entityForm.controls['city']" />
                  </div>
      
                  <div class="width-120" style="z-index: 100000;">
                    <div class="mt-3">
                      <label for="state" class="form-title">State/County</label>
                      <input
                      id="state"
                      type="text"
                      class="form-control ft-width"
                      placeholder=""
                      formControlName="state"
                    />
                    </div>
                    <app-form-error-component [control]="entityForm.controls['state']" />
                  </div>
      
                  <div class="width-110">
                    <div class="mt-3">
                      <label for="area" class="form-title">Area</label>
                      <input
                        id="area"
                        type="text"
                        class="form-control ft-width"
                        placeholder=""
                        formControlName="area"
                      />
                    </div>
                    <app-form-error-component [control]="entityForm.controls['area']" />
                  </div>
      
                  <div class="width-110">
                    <div class="mt-3">
                      <label for="postCode" class="form-title">Post Code</label>
                      <input
                        id="postCode"
                        type="text"
                        class="form-control ft-width"
                        placeholder=""
                        formControlName="postCode"
                      />
                    </div>
                    <app-form-error-component [control]="entityForm.controls['postCode']" />
                  </div>
      
                  <div class="width-120">
                    <div class="mt-3">
                      <label for="country" class="form-title">
                        <span class="required">*</span>Country</label>
                      <ng-select class="custom"
                                 id="country"
                                 [style.flex]="1"
                                 [items]="countriesList"
                                 bindLabel="name"
                                 bindValue="id"
                                 placeholder="All"
                                 formControlName="country">
                      </ng-select>
                    </div>
                    <app-form-error-component [control]="entityForm.controls['country']" />
                  </div>
      
                  <div class="width-150">
                    <div class="mt-3">
                      <label for="phone" class="form-title">Phone</label>
                      <div class="d-flex">
                      <input
                        style="width: 35px"
                        id="phone"
                        type="text"
                        appOnlyNumber
                        class="form-control ft-width inputNumberText me-1"
                        placeholder=""
                        formControlName="phoneCountryCode"
                      />
                      <input
                        style="width: 35px"
                        type="text"
                        appOnlyNumber
                        class="form-control ft-width inputNumberText me-1"
                        placeholder=""
                        formControlName="phoneAreaCode"
                      />
                      <input
                        style="width: 60px"
                        id="phoneNumber"
                        type="text"
                        appOnlyNumber
                        class="form-control ft-width inputNumberText me-1"
                        placeholder=""
                        formControlName="phoneNumber"
                      />
                      </div>
                    </div>
                    <app-form-error-component [control]="entityForm.controls['phoneNumber']" />
                  </div>
      
                  <div class="width-120">
                    <div class="mt-3">
                      <label for="bank" class="form-title">Bank</label>
                      <ng-select class="custom"
                                 id="bank"
                                 [style.flex]="1"
                                 [items]="banksList"
                                 bindLabel="name"
                                 bindValue="id"
                                 placeholder="All"
                                 formControlName="bankId"
                                 (change)="handleBankChange($event)">
                      </ng-select>
                    </div>
                    <app-form-error-component [control]="entityForm.controls['bank']" />
                  </div>
      
                  <div class="width-135">
                    <div class="mt-3">
                      <label for="accountNumber" class="form-title">Bank Account</label>
                      <input
                        id="accountNumber"
                        type="text"
                        class="form-control ft-width"
                        placeholder=""
                        appOnlyNumber
                        formControlName="accountNumber"
                      />
                    </div>
                    <app-form-error-component [control]="entityForm.controls['accountNumber']" />
                  </div>
      
                  <div class="width-130">
                    <div class="mt-3">
                      <label for="sortCode" class="form-title">Sort Code</label>
                      <input
                        id="sortCode"
                        type="text"
                        class="form-control ft-width"
                        placeholder=""
                        maxlength="14"
                        formControlName="sortCode"
                      />
                    </div>
                    <app-form-error-component [control]="entityForm.controls['sortCode']" />
                  </div>
      
                  <div class="width-145">
                    <div class="mt-3">
                      <label for="swiftBic" class="form-title">SWIFT BIC</label>
                      <input
                        id="swiftBic"
                        type="text"
                        class="form-control ft-width"
                        placeholder=""
                        maxlength="8"
                        style="text-transform: uppercase;"
                        formControlName="swiftBic"
                      />
                    </div>
                    <app-form-error-component [control]="entityForm.controls['swiftBic']" />
                  </div>
      
                  <div class="width-200">
                    <div class="mt-3">
                      <label for="iban" class="form-title">IBAN</label>
                      <input
                        id="iban"
                        type="text"
                        class="form-control ft-width"
                        placeholder=""
                        maxlength="34"
                        formControlName="iban"
                        style="text-transform: uppercase;"
                      />
                    </div>
                    <app-form-error-component [control]="entityForm.controls['iban']" />
                  </div>
               
      
      
                  <div class="col-md-12">
                    <div class="mt-3">
                      <label for="doc" class="form-title">Documents</label>
                      <div id="doc" class="d-flex justify-content-between">
                        <div class="d-flex align-items-center">
                          <label for="etr" class="form-title me-1 mt-1">ETR</label>
                          <input formControlName="etrFile" id="etr" type="checkbox">
                        </div>
                        <div class="d-flex align-items-center">
                          <label for="contract" class="form-title me-1 mt-1">Contract</label>
                          <input formControlName="organisationFile" id="contract" type="checkbox">
                        </div>
                        <div class="d-flex align-items-center">
                          <label for="po" class="form-title me-1 mt-1">PO</label>
                          <input formControlName="POFile" id="po" type="checkbox">
                        </div>
                        <div class="d-flex align-items-center">
                          <label for="pod" class="form-title me-1 mt-1">PoD</label>
                          <input formControlName="PoDFile" id="pod" type="checkbox">
                        </div>
                        <div class="d-flex align-items-center">
                          <label for="signed" class="form-title me-1 mt-1">Signed</label>
                          <input formControlName="signedFile" id="signed" type="checkbox">
                        </div>
                        <div class="d-flex align-items-center">
                          <label for="fx" class="form-title me-1 mt-1">Fx</label>
                          <input formControlName="fxFile" id="fx" type="checkbox">
                        </div>
                        <div class="d-flex align-items-center">
                          <label for="proof" class="form-title me-1 mt-1">Proof</label>
                          <input formControlName="proofFile" id="proof" type="checkbox">
                        </div>
                      </div>
                    </div>
                  </div>
      
                  <div class="col-12 bottom-fixed">
                    <hr />
                    <div class="d-flex justify-content-center mt-3">
                      <button [disabled]="entityForm.invalid" type="submit"
                              class="btn btn-light me-3 btn-custom-footer">
                        Save
                      </button>
                      <button
                        type="button"
                        class="btn btn-light btn-custom-footer"
                        data-dismiss="modal"
                        (click)="closeModal()"
                      >
                        Cancel
                      </button>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
</div>
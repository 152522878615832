import { Injectable } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { EntityItem } from "../../../../shared/interfaces/entity-response.interface";
import { formatPhoneNumber } from "../../../../shared/helpers/utils";

@Injectable({ providedIn: 'root' })
export class EntityFormProvider {
  entityForm: FormGroup;

  constructor(
    private fb: FormBuilder,
  ) {
  }

  initEntityForm(data: any): void {
    let formatedPhoneNumber;
    if (data) {
      formatedPhoneNumber = formatPhoneNumber(this.detectProperty(data?.entityProperties, 'phone_number'))
    }
    this.entityForm = this.fb.group({
      roleId: [data?.id ? data?.entityRoles[0]?.role?.name: 'Creditor', [Validators.required]],
      name: [data?.name, [Validators.required]],
      tradingAs: [data?.tradeName], //@todo not in data, need to add response
      previousName: [null], //@todo not in data, need to add response
      type: [data?.type, [Validators.required]],
      isPrevious: [data?.isPrevious],
      phoneCountryCode: [data?.entityProperties.length ? formatPhoneNumber(this.detectProperty(data?.entityProperties, 'phone_number'))[0] : null],
      phoneAreaCode: [data?.entityProperties.length ? formatPhoneNumber(this.detectProperty(data?.entityProperties, 'phone_number'))[1] : null],
      phoneNumber: [data?.entityProperties.length ? formatPhoneNumber(this.detectProperty(data?.entityProperties, 'phone_number'))[2] : null],
      email: [data?.entityProperties.length ? this.detectProperty(data?.entityProperties, 'email') : null, [Validators.email]],
      bankId: [data?.entityRoles[0]?.bankAccounts?.[0]?.bankId], // @todo bank name is null in response
      bankName: [data?.entityRoles[0]?.bankAccounts?.[0]?.name], // @todo bank name is null in response
      accountNumber: [data?.entityRoles[0]?.bankAccounts?.[0]?.account],
      sortCode: [data?.entityRoles[0]?.bankAccounts?.[0]?.sortCode],
      swiftBic: [data?.entityRoles[0]?.bankAccounts?.[0]?.swiftBic],
      iban: [data?.entityRoles[0]?.bankAccounts?.[0]?.iban],
      address1: [data?.addresses[0]?.lineOne, [Validators.required]],
      address2: [data?.addresses[0]?.lineTwo],
      area: [data?.addresses[0]?.area],
      city: [data?.addresses[0]?.city, [Validators.required]],
      state: [data?.addresses[0]?.state],
      postCode: [data?.addresses[0]?.zipCode],
      country: [data?.addresses[0]?.country, [Validators.required]],
      POFile: [data?.documents?.POFile],
      PoDFile: [data?.documents?.PoDFile],
      etrFile: [data?.documents?.etrFile],
      fxFile: [data?.documents?.fxFile],
      organisationFile: [data?.documents?.organisationFile],
      proofFile: [data?.documents?.proofFile],
      signedFile: [data?.documents?.signedFile],
      comments: [null], //@todo not in data, need to add response
    })
  }

  private detectProperty(data: any[], propertyId: string): string {
    const property = data.find((item) => item.propertyId === propertyId);
    return property?.propertyValue || "";
  }
}

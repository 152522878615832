import { Directive, ElementRef, forwardRef, HostListener, Input, Renderer2 } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

@Directive({
  selector: '[appFormatPhone]',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => FormatPhoneDirective),
      multi: true,
    },
  ],
})
export class FormatPhoneDirective implements ControlValueAccessor {
  @Input() appFormatPhone: boolean | '' = false;
  private onChange!: (val: string | number) => void;
  private onTouched!: () => void;
  private value!: string | number;

  constructor(
    private elementRef: ElementRef,
    private renderer: Renderer2,
  ) {}

  @HostListener('input', ['$event.target.value'])
  onInputChange(value: string) {
    const filteredValue: string = this.filterValue(value);
    const formattedValue = this.formatPhoneNumber(filteredValue);
    this.updateTextInput(formattedValue, this.value !== formattedValue);
  }

  @HostListener('blur')
  onBlur() {
    if (this.appFormatPhone) {
      this.onValueFormatting();
    }
  }

  @HostListener('focus')
  onFocus() {
    if (!this.value) {
      return;
    }
    this.updateTextInput(String(this.value), false);
  }

  private updateTextInput(value: string | number, propagateChange: boolean) {
    this.renderer.setProperty(this.elementRef.nativeElement, 'value', value);
    if (propagateChange) {
      this.onChange(value);
    }
    this.value = value;
  }

  onValueFormatting() {
    if (!this.value) {
      return;
    }
    this.updateTextInput(String(this.value), false);
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    this.renderer.setProperty(this.elementRef.nativeElement, 'disabled', isDisabled);
  }

  writeValue(value: any): void {
    value = value ? String(value) : '';
    this.updateTextInput(value, false);
  }

  private filterValue(value: string): string {
    return value.replace(/[^0-9]/g, ''); // Keep only digits
  }

  private formatPhoneNumber(value: string): string {
    if (value.length > 6) {
      return `${value.substring(0, 3)} - ${value.substring(3, 6)} - ${value.substring(6, 10)}`;
    } else if (value.length > 3) {
      return `${value.substring(0, 3)} - ${value.substring(3)}`;
    } else {
      return value;
    }
  }
}
export const PAGE_SIZES = [50, 100, 200, 500, 1000, 2500, 5000, 10000];
export const CURRENCIES = [
  {
    id: 'EUR',
    name: 'EUR',
    code: 'EUR',
  },
  {
    id: 'GBP',
    name: 'GBP',
    code: 'GBP',
  },
  {
    id: 'USD',
    name: 'USD',
    code: 'USD',
  },
];

export const CLUTCH: Array<{ id: string; name: string }> = [
  { id: '', name: 'All' },
  { id: 'Receipts', name: 'Receipts' },
  { id: 'Payments', name: 'Payments' },
];

export const TYPE_DATA: Array<{ id: string; name: string }> = [
  {
    id: 'Transfer',
    name: 'Transfer',
  },
  {
    id: 'Cheques',
    name: 'Cheques',
  },
  {
    id: 'Miscellaneous',
    name: 'Miscellaneous',
  },
  {
    id: 'Inward Payment via FP',
    name: 'Inward Payment via FP',
  },
  {
    id: 'Remittance',
    name: 'Remittance',
  },
  {
    id: '',
    name: '',
  },
];

export const TRANSACTION: Array<{ id: string; name: string }> = [
  { id: 'Receipt', name: 'Receipt' },
  { id: 'Compound', name: 'Compound' },
  { id: 'Redemption', name: 'Redemption' },
];

//todo need to change name
export const TRANSACTION_TYPE: Array<{ id: string; name: string }> = [
  { id: 'Demand', name: 'Demand' },
  { id: 'Term', name: 'Term' },
  { id: 'Fixed', name: 'Fixed' },
];

export const ALL_TRANSACTION_TYPE: Array<{ id: string; name: string }> = [
  { id: 'RPA Receipt', name: 'RPA Receipt' },
  { id: 'Compound', name: 'Compound' },
  { id: 'Addition', name: 'Addition' },
  { id: 'Full Redemption', name: 'Full Redemption' },
  { id: 'Coupon', name: 'Coupon' },
  { id: 'Renewal', name: 'Renewal' },
  { id: 'Partial Coupon', name: 'Partial Coupon' },
];

//todo need to change name
export const OLD_INVESTMENT: Array<{ id: string; name: string }> = [
  { id: 'Investabill', name: 'Investabill' },
  { id: 'Leasabill', name: 'Leasabill' },
];

export const PRODUCT: Array<{ id: string; name: string }> = [
  { id: 'ETR', name: 'ETR' },
  { id: 'ETS', name: 'ETS' },
];

export const BANKS: Array<{ name: string }> = [
  { name: 'Barclays Bank' },
  { name: 'Piraeus Bank' },
  { name: 'Danske Bank' },
];

export const CREDEBTS: Array<{ name: string }> = [
  { name: 'Match' },
  { name: 'Mismatch' },
];

export const TRADE_TRANSACTION_TYPE = [
    { name: 'All' , id: ''},
    { name: 'a-ETR' , id: 'a' },
    { name: 'b-ETR' , id: 'b'},
    { name: 'c-ETR', id: 'c' },
    { name: 'd-ETR' , id: 'd'},
    { name: 'f-ETR' , id: 'f'}
];

export const TOOLTIP_DATA_ETR = {
    originatorId: 'Originator ID',
    originatorName: 'Originator Name',
    debtorId: 'Debtor ID',
    debtorName: 'Debtor Name',
    creditorId: 'Creditor ID',
    creditorName: 'Creditor Name',
    credebtorId: 'Credebtor ID',
    credebtorName: 'Credebtor Name',
    leaseeId: 'Leasee ID',
    leaseeName: 'Leasee Name',
    batch: 'Batch',
    ledger: 'Ledger',
    type: 'Type',
    classification: 'Classification',
    issuedDate: 'Issued',
    ccy: 'Ccy',
    comments: 'Comments',
    tradeId: 'Trade Id',
    ref: 'Ref',
    postedDate: 'Posted',
    faceValue: 'Face Value',
    deposit: 'Deposit',
    tradedDate: 'Traded',
    logTraded: 'Log Traded',
    months: 'Months',
    edso: 'EDSO',
    purchasedValue: 'Purchased Value',
    sellType: 'Sell Type',
    sellRate: 'Sell Rate',
    days: '180 days',
    disable180Days: 'Disable 180 days',
    ldcPremium: 'LDC Premium',
    agentPremium: 'Agent Premium',
    tradedFx: 'Traded Fx',
    settledDate: 'Settled Date',
    tradeCommission: 'Trade Commission',
    settledFx: 'Settled Fx',
    closedDate: 'Closed Date',
    closedFx: 'Closed Fx',
    unitPrice: 'Unit Price',
    quantity: 'Quantity',
    eoT: 'EOT',
    vatIncluded: 'Vat Included',
    vatOnPurchases: 'Vat on Purchases',
    idtkoi: 'IDTKOI',
    notes: 'Notes',
    eot: 'EOT',
    resumedAt: 'Resumed Date',
    pausedAt: 'Paused Date',
    no180: 'No 180',

};
export const ETR_TYPE = [
  { name: 'a-ETR' , id: 'a' },
  { name: 'b-ETR' , id: 'b'},
  { name: 'c-ETR', id: 'c' },
  { name: 'd-ETR' , id: 'd'},
  { name: 'f-ETR' , id: 'f'}
];

export const TOOLTIP_DATA_ORGANISATION = {
  entityID: 'Entity ID',
  role: 'Role',
  entityName: 'Entity Name',
  phoneNumber: 'Phone Number',
  mobileNumber: 'Mobile Number',
  email: 'Email',
  address1: 'Address1',
  address2: 'Address2',
  area: 'Area',
  city: 'City',
  state: 'State',
  country: 'Country',
  postCode: 'Post Code',
  prefix: 'Prefix',
  accountName: 'Account Name',
  sortCode: 'Sort Code',
  swiftBIC: 'SWIFT/BIC',
  iBAN: 'IBAN',
  accountNumber: 'Account Number',
};

export const TOOLTIP_DATA_ASSET = {
  assetID: 'Asset ID',
  assetName: 'Asset Name',
  batch: 'Batch',
  quantity: 'Quantity',
  ccy: 'Ccy',
  unitPrice: 'Unit Price',
  classification: 'Classification',
  description: 'Description',
  serialNumber: 'Serial Number',
  months: 'Months',
  repairAndMaintenance: 'Repair & Maintenance',
  eot: 'EoT Value',
  vatIncluded: 'VAT',
  vatOnPurchases: 'VAT Purchase',
  creditorId:'Creditor',
  issuedDate:'Issued Date',
  purchasedDate:'Issued Date',
  leaseeId:'Leasee',
  sellRate:'Sell Rate',
  purchasedValue:'Purchased Value',
  faceValue:'Face Value',
  tradedDate:'Traded Date',
  deposit:'Deposit',
  originatorId:'Originator',
  file:'Document Upload',
  sellType:'Sell Type',
  type:'Type',
};

export const Savings_Json = [
  { id: 'Deposit', name: 'Deposit' },
  { id: 'Pension', name: 'Pension' },
]

export const CCY_SORT_ORDER = ["EUR", "GBP", "USD", "CAD", "CHF", "HKD", "NZD"];